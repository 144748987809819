import React, { Fragment } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'
import HeaderSection from './HeaderSection'
import FooterSection from './FooterSection'
import { createMuiTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import HelvetivaForTargetNormal from '../../fonts/HelveticaForTarget/Medium/b7489e1d-53c6-414e-a325-5adf8e204b8d.woff2'
import Settings from './../SiteData/Settings'
import Routes from './Routes'

const { creatorsHeaderMenu, headerMenu, footerMenu, companyData } = Settings

const targeticaFont = {
  fontFamily: 'HelveticaForTarget',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `
    local('HelveticaForTarget'),
    url(${HelvetivaForTargetNormal}) format('woff2'),
  `,
}

let siteTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#CC0000',
    }
  },
  typography: {
    body1: {
      fontSize: '1.125rem',
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [targeticaFont],
      },
    },
  }
})

siteTheme = responsiveFontSizes(siteTheme, {factor: 3})

// 16px
// calculating the rest by dividing by 16

siteTheme.typography.h2 = {
  fontSize: '1.9rem',
  lineHeight: '3rem',
  color: '#333333',
  [siteTheme.breakpoints.down('md')]: {
    fontSize: '1.6rem',
  },
  [siteTheme.breakpoints.down('xs')]: {
    fontSize: '1.6rem',
    lineHeight: '2rem'
  },
}

siteTheme.typography.h3 = {
  fontSize: '1.872rem',
  lineHeight: '3rem',
  color: '#333333',
  [siteTheme.breakpoints.down('md')]: {
    fontSize: '1.5rem',
  },
  [siteTheme.breakpoints.down('xs')]: {
    fontSize: '1.5rem',
    lineHeight: '2rem'
  },
}

siteTheme.typography.h4 = {
  fontSize: '1.4rem',
  marginTop: 0,
  marginBottom: 0,
  lineHeight: '3rem',
  [siteTheme.breakpoints.down('md')]: {
    fontSize: '1.25rem',
  },
  [siteTheme.breakpoints.down('xs')]: {
    fontSize: '1.1rem',
  },
}

siteTheme.typography.body1 = {
  fontSize: '1rem',
  color: '#333333',
  [siteTheme.breakpoints.down('xs')]: {
    fontSize: '1rem',
    lineHeight: '1.8rem'
  }
}

const Layout = (props) => {

  const location = useLocation();

  return (
    <Fragment>
      <ThemeProvider theme={siteTheme}>
        <CssBaseline />
        <HeaderSection menu={location.pathname==="/creators" ? creatorsHeaderMenu : headerMenu} />
        <main id='main-content'>
          <Routes />
        </main>
        <FooterSection
          footerMenu={footerMenu}
          companyData={companyData}
        />
      </ThemeProvider>
    </Fragment>
  )
}

export default Layout
