const FaqPage = {
  breadcrumb: {
      tree: [
          { label: 'Home', slug: '/' },
          { label: 'Faqs', active: true }
      ]
  },
  
  textBox: {
      heading: 'Frequently asked questions',
      styling: {
          textBox: {
              paddingTop: 0,
              paddingBottom: 0
          },
          heading: {
              color: '#6D6D6D',
              padding: 18,
              fontSize: '2rem',
              textAlign: 'center'
          }
      }
  },

  brandBanner: {
    heading: 'Still got questions?',
    content: 'Drop a mail to <a href="mailto:targetpartners@target.com" class="anchortag">targetpartners@target.com</a>, <a href="mailto:targetcreators@target.com" class="anchortag">targetcreators@target.com</a> and we will get back to you as soon as possible.',
    externalLink: 'https://affiliates.target.com/campaign-promo-signup/Target.brand?execution=e1s1',
    styles: {
        box: {
            color: '#ffffff',
            margin: 0,
            marginBottom: 30
        },
        media: {
            padding: 10,
        },
     
        content: {
            fontSize: '0.895rem',
            color: '#ffffff',
            paddingBottom: '10px',
            marginBottom: 10
        },
        
        heading: {
            color: '#CC0000',
            lineHeight: '4rem'
        },
        link: {
            backgroundColor: '#ffffff',
            color: '#CC0000',
            borderRadius: '20px',
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingRight: '20px',
            paddingLeft: '20px',          
        },
        "&:hover": {
          textDecoration: "none !important",
        },
    }
}
  
}

export default FaqPage