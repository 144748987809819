import React from "react";
import { Typography, Link, makeStyles, Grid } from "@material-ui/core";
import { Link as RLink } from "react-router-dom";
import targetLogo from "../../images/target-logo.png";
import facebook from "../../images/facebook.png";
import twitter from "../../images/twitter.png";
import instagram from "../../images/instagram.png";
import pinterest from "../../images/pinterest.png";
import tiktok from "../../images/tiktok.png";

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    backgroundColor: "#000000",
    color: "#ffffff",
    textAlign: "center",
    //paddingTop: '3%',
    //paddingBottom: '9%',
    padding: "2%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      padding: "40px 142px 50px 0px",
    },
  },

  footerGridContainer: {
    textAlign: "left",
  },
  footerLink: {
    display: "block",
    textAlign: "center",
    //marginBottom: '18px',
    color: "#fff",
    textDecoration: "none",
    // font: 'Bold 18px/22px HelveticaForTarget',
  },
  footerLogo: {
    height: "48px",
  },
  tagline: {
    color: "#FFFFFF",
    textAlign: "center",
    fontSize: "0.8rem",
  },
  socialIconContainer: {
    alignItems: "center",
    display: "flex",
    // justifyContent: 'space-between',
    // padding: '0px 28px'
  },
  followusbox: {
    //marginTop: '60px',
    [theme.breakpoints.down("lg")]: {
      //marginTop: '60px',
      textAlign: "center",
    },
    [theme.breakpoints.down("md")]: {
      //marginTop: '60px',
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      //marginTop: '60px',
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      //marginTop: '0px',
      textAlign: "center",
    },
  },
  footerLinkFollow: {
    display: "block",
    color: "white",
    textAlign: "right",
  },
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
  },
}));

export default function FooterSection(props) {
  
  const { footerMenu, companyData } = props;
  
  const classes = useStyles();

  return (
    <footer className={classes.footerContainer} id="footer" role="contentinfo">
      <div>
        <Grid
          container
          spacing={3}
          className="footerGridContainer"
          justify="center"
          alignItems={"center"}
        >
          {/* Logo div */}
          <Grid item xs={12} lg={1}>
            <img
              src={targetLogo}
              className={classes.footerLogo}
              alt="Target logo"
            />
          </Grid>
          {/* second div */}

          <Grid item xm={12} lg={1}>
            <Link
              className={classes.footerLink}
              href={footerMenu[0].slug}
              target={"_blank"}
            >
              {footerMenu[0].title}
            </Link>
          </Grid>

          <Grid item xm={12} lg={2}>
            <RLink className={classes.footerLink} to={footerMenu[2].slug}>
              {footerMenu[2].title}
            </RLink>
          </Grid>

          <Grid item xm={12} lg={1}>
            <Link
              className={classes.footerLink}
              href={footerMenu[3].slug}
              target={"_blank"}
            >
              {footerMenu[3].title}
            </Link>
          </Grid>
          
         
          {/* third div */}
          <Grid item xm={12} lg={1}>
            <RLink className={classes.footerLink} to={footerMenu[1].slug}>
              {footerMenu[1].title}
            </RLink>
          </Grid>

          <Grid item xm={12} lg={1}>
              <Link
                className={classes.footerLink}
                href={footerMenu[4].slug}
                target={"_blank"}
              >
                {footerMenu[4].title}
              </Link>
            </Grid>
          
       
          {/* fourth div */}

          <Grid item xm={12} lg={1} className={classes.followusbox}>
            <Typography component="span" className={classes.footerLinkFollow}>
              {companyData.social.heading}
            </Typography>
          </Grid>

          <Grid item xm={12} lg={2}>
            <div className={classes.socialIconContainer}>
              <Link
                href={companyData.social.links.facebook.url}
                target={"_blank"}
              >
                <img
                  className="social-icons"
                  src={facebook}
                  alt="Follow us on Facebook"
                />
              </Link>

              <Link
                href={companyData.social.links.instagram.url}
                target={"_blank"}
              >
                <img
                  className="social-icons"
                  src={instagram}
                  alt="Follow us on Instagram"
                />
              </Link>

              <Link
                href={companyData.social.links.twitter.url}
                target={"_blank"}
              >
                <img
                  className="social-icons"
                  src={twitter}
                  alt="Follow us on Twitter"
                />
              </Link>

              <Link
                href={companyData.social.links.pinterest.url}
                target={"_blank"}
              >
                <img
                  className="social-icons"
                  src={pinterest}
                  alt="Follow us on pinterest"
                />
              </Link>

              <Link
                href={companyData.social.links.tiktok.url}
                target={"_blank"}
              >
                <img
                  className="social-icons"
                  src={tiktok}
                  alt="Follow us on tiktok"
                />
              </Link>
            </div>
          </Grid>
        </Grid>
      </div>
    </footer>
  );
}
