import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { Typography, Grid } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#CC0000",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    color: "#777777",
    display: "flex",
    padding: "25px",
    paddingBottom: "40px",
    alignItems: "center",
    marginLeft: "10%",
    marginRight: "10%",
    borderRadius: "25px",
    // cursor: 'pointer',
  },

  heading: {
    fontSize: "1.4rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",

    }
  },

  contentPlace: {
    height: "auto",
    paddingLeft: theme.spacing(11),
    paddingRight: theme.spacing(11),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
     // padding: theme.spacing(4) + "px !important",
      //paddingTop: "0px !important",
    },
    
  },

  arrowposition: {
    flexDirection: 'row-reverse',
    padding: 0,
    marginRight: 10,

    "& svg":{
      width: "2em",
      height: "2em",
    }
  },

  
  
}));

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},

})(MuiAccordion);


const AccordionSummary = withStyles((theme) => ({
  root: {
    minHeight: 56,
    borderBottom: "1px solid #A2A2A2",
    fontWeight: "bold",
    paddingLeft: theme.spacing(0),
    paddingTop: 8,
    fontSize: "2rem",
    paddingBottom: 8,
    "&$expanded": {
      minHeight: 56,
    },
  },

  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },

  expanded: {},
  
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    backgroundColor: "#F3F1F2",
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(8),
  },
}))(MuiAccordionDetails);

export default function CustomizedAccordions(props) {
  const [expanded, setExpanded] = React.useState("panel1");
  const classes = useStyles();
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };


  return (
    <div>
      <div>
      <Grid container spacing={0} className={classes.contentPlace}>
      <Grid item xs={12}>
        <Accordion
          square
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            expandIcon={<ExpandMoreIcon />}
            aria-label="What is the Target Partners Program and how does it work?"
            className={classes.arrowposition}
            // classes = {{
            //   expandIcon: classes.nospace,
            // }}
          >
            <Typography className={classes.heading} variant="h3">
              What is the Target Partners Program and how does it work?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              The Partners Program is a service that allows participating
              websites to earn commission on sales referred to Target.com using
              specially tracked links provided via Impact Radius. When you apply
              for the program via Impact Radius and are accepted as an associate
              of Target, you may place links that include Target products via
              banners, widgets, text links and other tools or creatives on your
              website. When your visitors click through to the Target website
              via these links, that visitor is tracked within a 7-day cookie
              window until they place an order. When an order is placed by such
              a visitor, the order is associated with your website as the
              referring source and your Impact Radius partner account is
              credited a commission for the order. So the more visitors you
              refer to Target and the more of those visitors who convert into
              Target customers, the more you get paid. What's more? With our
              commission structure, unlike many other websites, you get paid
              each time that customer places an order within 7 days of clicking
              on your affiliate link, not just the first time!
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            aria-controls="panel2d-content"
            id="panel2d-header"
            expandIcon={<ExpandMoreIcon />}
            aria-label="How do I become a Partner with Target?"
            className={classes.arrowposition}
          >
            <Typography className={classes.heading} variant="h3">How do I become a Partner with Target?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              It’s super simple, all you have to do to join the <a href="https://affiliates.target.com/campaign-promo-signup/Target.brand?execution=e1s1">Target Partners
              Program.</a>
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            aria-controls="panel3d-content"
            id="panel3d-header"
            expandIcon={<ExpandMoreIcon />}
            aria-label="Who can become a Target Partner?"
            className={classes.arrowposition}
          >
            <Typography className={classes.heading} variant="h3">Who can become a Target Partner?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Any website owner, blogger or influencer that runs a
              family-friendly website and has mainly US-based viewership is
              eligible to join the Target Partners Program, subject to the
              program’s terms and conditions.
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            aria-controls="panel4d-content"
            id="panel4d-header"
            expandIcon={<ExpandMoreIcon />}
            aria-label="Can websites outside of the U.S. be a part of the Partners Program?"
            className={classes.arrowposition}
            // classes = {{
            //   expandIcon: classes.nospace,
            // }}
          >
            <Typography className={classes.heading} variant="h3">
              Can websites outside of the U.S. be a part of the Partners
              Program?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Yes. We accept influencers and associates from anywhere in the
              world, as long as Impact Radius supports your country of residence
              and your content is directed toward the U.S. market.
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
        >
          <AccordionSummary
            aria-controls="panel5d-content"
            id="panel5d-header"
            expandIcon={<ExpandMoreIcon />}
            aria-label="How do I apply for the program if I have multiple websites?"
            className={classes.arrowposition}
            >
            <Typography className={classes.heading} variant="h3">
              How do I apply for the program if I have multiple websites?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              You can apply for the program under your company profile and then
              list additional websites that you may promote Target on in your
              account.
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
        >
          <AccordionSummary
            aria-controls="panel6d-content"
            id="panel6d-header"
            expandIcon={<ExpandMoreIcon />}
            aria-label="Are there any fees or costs associated with becoming a Target partner?"
            className={classes.arrowposition}
          >
            <Typography className={classes.heading} variant="h3">
              Are there any fees or costs associated with becoming a Target
              partner?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              No, there is no charge to join the Target Partners Program. It is
              free to sign up and free to add banners, widgets and links to your
              site.
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          expanded={expanded === "panel7"}
          onChange={handleChange("panel7")}
        >
          <AccordionSummary
            aria-controls="panel7d-content"
            id="panel7d-header"
            expandIcon={<ExpandMoreIcon />}
            aria-label="Does Target have a dedicated program manager to contact for more questions?"
            className={classes.arrowposition}>
            <Typography className={classes.heading} variant="h3">
              Does Target have a dedicated program manager to contact for more
              questions?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Yes, we are committed to making our Partners successful. Please
              reach out to us at <a href="mailto:targetpartners@target.com">targetpartners@target.com</a>, <a href="mailto:targetcreators@target.com">targetcreators@target.com</a> with any
              questions.
            </p>
          </AccordionDetails>
        </Accordion>
        
        <Accordion
          square
          expanded={expanded === "panel9"}
          onChange={handleChange("panel9")}
        >
          <AccordionSummary
            aria-controls="panel9d-content"
            id="panel9d-header"
            expandIcon={<ExpandMoreIcon />}
            aria-label="What is a cookie?"
            className={classes.arrowposition}
            
          >
            <Typography className={classes.heading} variant="h3">What is a cookie?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              A cookie is a small piece of data that is transferred to a
              computer in order to mark it for a later transaction. Partners
              Programs use cookies to track customers who have clicked on links
              from your site and made purchases so you can get your commission.
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          expanded={expanded === "panel10"}
          onChange={handleChange("panel10")}
        >
          <AccordionSummary
            aria-controls="panel10d-content"
            id="panel10d-header"
            expandIcon={<ExpandMoreIcon />}
            aria-label="What is the cookie length?"
            className={classes.arrowposition}
            // classes = {{
            //   expandIcon: classes.nospace,
            // }}
          >
            <Typography className={classes.heading} variant="h3">What is the cookie length?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              The cookie length is 7 days. You get credit for any order within 7
              days of the original click placed by the customer.
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          expanded={expanded === "panel11"}
          onChange={handleChange("panel11")}
        >
          <AccordionSummary
            aria-controls="panel11d-content"
            id="panel11d-header"
            expandIcon={<ExpandMoreIcon />}
            aria-label="What is E.P.C? What is Target’s E.P.C?"
            className={classes.arrowposition}
            // classes = {{
            //   expandIcon: classes.nospace,
            // }}
          >
            <Typography className={classes.heading} variant="h3">What is E.P.C? What is Target’s E.P.C?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              E.P.C stands for estimated Earnings Per 100 Clicks. Target’s E.P.C
              is approximately $12-$13. This means that the average payout for
              every 100 clicks is $12-$13.
            </p>
          </AccordionDetails>
        </Accordion>
        </Grid>
        </Grid>
      </div>
    </div>
  );
}
