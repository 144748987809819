import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Link, Grid } from "@material-ui/core";
import { motion, useAnimation } from "framer-motion";
import { BrandBannerEffect } from "../../Utils/AnimationEffects";
import { useInView } from "react-intersection-observer";
import parse from 'html-react-parser';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#CC0000",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(6),
    color: "#777777",
    display: "flex",
    padding: "10px",
    paddingBottom: "20px",
    alignItems: "center",
    marginLeft: "10%",
    marginRight: "10%",
    borderRadius: "25px",
    // cursor: 'pointer',
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      paddingLeft: "0px !important",
      margin: theme.spacing(3),
    },
  },

  contentDiv: {
    color: "#484848",
    fontSize: 36,
    paddingLeft: 37,
    [theme.breakpoints.down("md")]: {
      fontSize: 26,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "16px 16px 0 16px",
      fontSize: 18,
    },
  },

  mediaPlace: {
    height: 220,
    [theme.breakpoints.down("sm")]: {
      // padding: theme.spacing(6),
      paddingBottom: theme.spacing(2),
    },
  },
  contentPlace: {
    height: "auto",
    padding: theme.spacing(2),
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(3),
      textAlign: "center",
     // padding: theme.spacing(4) + "px !important",
      //paddingTop: "0px !important",
    },
  },
  heading: {
    color: '#fff',
    paddingTop: "0 !important",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "2rem !important",
      fontSize: "1.5rem",
      textAlign: "center",
    },
  },
  content: {
    "&:a": {
      color: "#ffffff",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
      textAlign: "center",
},
  },
  link: {
    color: "#ffffff",
    "&:hover": {
        textDecoration: "underline",
      },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem !important",
      textAlign: "center",
    },
  },


}));

export default function BrandBanner(props) {
  const { values } = props;
  const { content, heading, slug, styles, externalLink } = values;
  const classes = useStyles();
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: false });

  const defaultContentStyle = {};
  const defaultLinkStyle = {};

  const contentStyle = Object.assign(
    {},
    defaultContentStyle,
    styles && styles.content ? styles.content : {}
  );
  const linkStyle = Object.assign(
    {},
    defaultLinkStyle,
    styles && styles.link ? styles.link : {}
  );

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        controls.start("visible");
      }, 300);
    }
  }, [controls, inView]);

  return (
    <div ref={ref}>
      <motion.div
        animate={controls}
        initial="hidden"
        {...BrandBannerEffect}
        className={classes.bannerText}
      >
        <div className={classes.root}>
          <Grid container spacing={0}>
            <Grid item xs={12} className={classes.contentPlace}>
            {heading && <Typography variant={'h3'} className={classes.heading}>{heading}</Typography>}
              <Typography style={contentStyle} className={classes.content}>
              {parse(content)}
              </Typography>
              {slug && (
                <Link href={slug} style={linkStyle} className={classes.link}>
                  Apply Now
                </Link>
              )}
              <Link
                href={externalLink}
                target={"_blank"}
                style={linkStyle}
                className={classes.link}
              >
                Apply Now
              </Link>
            </Grid>
          </Grid>
        </div>
      </motion.div>
    </div>
  );
}
