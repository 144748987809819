import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import './App.css'
import './styles/main.scss'
import './styles/animate.min.css'
import { BrowserRouter } from 'react-router-dom'
import Layout from './components/Layout/Layout'
import ScrollToTop from './components/Layout/ScrollToTop'

function App() {
  return (
    <Fragment>
      <BrowserRouter>
        <Helmet htmlAttributes={{}}>
          <meta charSet='utf-8' />
          <html lang='en' />
          <title>Partners</title>
          <meta
            name='description'
            content='Partners'
          />
          <meta name='viewport' content='width=device-width, initial-scale=1' />
          {/* Add general page wide keywords below */}
          <meta
            name='keywords'
            content='Partners'
          />
        </Helmet>
          <ScrollToTop>
            <Layout />
          </ScrollToTop>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
