const HomePage = {
  hero: {
    banners:[
      {
        primaryHeading: ["Partner with the"],
        secondaryHeading: ["brand you love"],
        subTextPrimary: [
          "Earn up to 8% when you promote your fave Target finds with our Partners Program.",
        ],
        subTextSecondary: [
          "It’s always on & open to all so you can ease into content creation anytime, whether you’re an influencer, blogger or affiliate.",
        ],
        backgroundImage: [
          "https://gfc.target.com/cmstitestpoc/hero_banner.png",
          "https://gfc.target.com/cmstitestpoc/hero_mobile_banner.png",
        ],
      },
      {
        primaryHeading: ["Introducing"],
        secondaryHeading: ["Target Creators!"],
        subTextPrimary: [
          "A platform to curate & grow your personal brand.",
        ],
        subTextSecondary: [""],
        backgroundImage: [
          "https://gfc.target.com/cmstitestpoc/hero_banner.png",
          "https://gfc.target.com/cmstitestpoc/hero_mobile_banner.png",
        ],
      },
    ]
  },
  loveBox: {
    primaryHeading: ["So much to love"],

    loveStories: [
      {
        storyImage: [
          "https://gfc.target.com/cmstitestpoc/partnership.png",
        ],
        title: ["Partnership made oh-so-easy"],
        description: [
          "Quick sign-up, opportunities for commission & easy tracking links through Impact drive your audience to your recommendations.",
        ],
      },
      {
        storyImage: ["https://gfc.target.com/cmstitestpoc/merrier.png"],
        title: ["The more the merrier"],
        description: [
          "Target’s commission structure could help you meet your #goals, because more sales equal more earnings (& more fun).",
        ],
      },
      {
        storyImage: [
          "https://gfc.target.com/cmstitestpoc/inspiration.png",
        ],
        title: ["Never run out of inspiration"],
        description: [
          "Enjoy newsletters, promos & deals each week to help you create amazing content whenever you feel inspired.",
        ],
      },
    ],
  },

  stepbox: {
    primaryHeading: ["3 easy steps to partner with us"],

    stepstory: [
      {
        backgroundImage: ["https://gfc.target.com/cmstitestpoc/shape1.png"],
        title: ["Sign up easily"],
        description: ["Get into the details with our application. It’s quick, easy & free.",
      ],
      },

      {
        backgroundImage: ["https://gfc.target.com/cmstitestpoc/shape2.png"],
        title: ["Inspire your audience"],
        description: ["Create content with popular brands, deals, promos & bring over 1 million products to life.",
      ],
      },

      {
        backgroundImage: ["https://gfc.target.com/cmstitestpoc/shape3.png"],
        title: ["Earn up to 8%"],
        description: ["Spread the word & cash in on every qualifying purchase made through your links.",
      ],
      },
    ],
  },

  brandBanner: {
    content: "Join the program & become a Target Partner",
    externalLink: "https://partner.target.com/campaign-promo-signup/Target.brand?execution=e1s1",
    styles: {
      box: {
        color: "#ffffff",
        margin: 0,
        marginBottom: 30,
      },
      media: {
        padding: 10,
      },
      contentBox: {
        paddingLeft: 10,
        paddingRight: 45,
      },
      content: {
        fontSize: "1.5rem",
        color: "#ffffff",
        fontWeight: "bold",
        paddingTop: "15px",
        paddingBottom: "20px",
        marginBottom: 0,
      },
      heading: {
        color: "#CC0000",
        lineHeight: "4rem",
      },
      link: {
        backgroundColor: "#ffffff",
        color: "#CC0000",
        borderRadius: "20px",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingRight: "20px",
        paddingLeft: "20px",
      },
      "&:hover": {
        textDecoration: "none !important",
      },
    },
  },
 

  info: {
    desc:
      "<b>Got more questions?</b> Check out our ",
    linkToPage: {
      slug: '/faqs',
      label: 'FAQs'
    },
    styling: {
       
      link: {
        color: '#333333',
        textDecoration: 'underline',
      }
    }
  },


};

export default HomePage;
