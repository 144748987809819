import CreatorsBackgroundDesktop from '../../images/hello_creators_background_desktop.png';
import CreatorsBackgroundMobile from '../../images/hello_creators_background_mobile.png';

import RadialGraphic from '../../images/radial-graphic.png';

const CreatorsData = {
  helloCreatorsBox: {
    primaryHeading: ["Hello,"],
    secondaryHeading: ["Target Creators!"],
    subTextPrimary: ["Your journey to build a brand partnership starts here"],
    backgroundImage: [
      CreatorsBackgroundDesktop,
      CreatorsBackgroundMobile,
    ],
  },

  featuresBox: {
    features: [
      {
        featureImage: ["https://gfc.target.com/cmstitestpoc/features-010.gif"],
        primaryHeading: ["Share content"],
        secondaryHeading: ["to learn, earn & grow"],
      },
      {
        featureImage: ["https://gfc.target.com/cmstitestpoc/features-020.gif"],
        primaryHeading: ["Strengthen"],
        secondaryHeading: ["Your Community"],
      },
      {
        featureImage: ["https://gfc.target.com/cmstitestpoc/features-030.gif"],
        primaryHeading: ["Get access"],
        secondaryHeading: ["to your own Storefronts"],
      },
    ],
  },

  joinCreatorsbox: {
    primaryHeading: ["Become a part of"],
    secondaryHeading: ["Target Creators"],
    backgroundImage: [RadialGraphic],
  },
};

export default CreatorsData;
