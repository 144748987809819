import React, { useState, useEffect } from "react";
import { Typography, Link, makeStyles } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import partnerLogoImg from "../../images/Target_Parnters_Logo.png";
import { useHistory, useLocation } from "react-router-dom";
import { Link as RLink } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  title: {
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      left: "2%",
      top: "32%",
      width: "30%",
    },
    width: 500,
  },
  button: {
    borderRadius: 20,
    textTransform: "none",
    borderColor: "#CC0000",
    color: "#CC0000",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    "&:first-child": {
      marginRight: 30,
    },
    [theme.breakpoints.down("sm")]: {
      "&:first-child": {
        marginRight: 10,
      },
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(0.5),
     paddingBottom: theme.spacing(0.5),

    },
    "&:hover": {
        background: "none",
      },
  },

  buttonActive: {
    borderRadius: 20,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textTransform: "none",
    boxShadow: "none !important",
    color: "#ffffff",
    [theme.breakpoints.down("sm")]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(0.6),
        paddingBottom: theme.spacing(0.6),
  
      },
    "&:hover": {
        background: "#CC0000",
      },
  },

  partnerLogo: {
    cursor: "pointer",
    width: 200,
    [theme.breakpoints.up("lg")]: {
      width: 200,
    },
    [theme.breakpoints.up("xl")]: {
      width: 200,
    },
    [theme.breakpoints.down("sm")]: {
      width: 150,
    },
  },
  logoLink: {
    "&:focus": {
      outline: "none",
    },
  },
  skipNav: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "fixed",
    width: 1,
    "&:focus": {
      background: "white",
      clip: "auto",
      height: "auto",
      left: 0,
      top: 0,
      width: "auto",
      margin: 0,
      zIndex: 1500,
      outline: 0,
      textDecoration: "underline",
      border: "1px dashed #d3d3d3",
      padding: 3,
    },
  },
  toolBar: {
    height: 90,
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(2),
    },
  },
  header: {
    boxShadow: "none !important",
  },
  nav: {
    width: "100%",
    display: "inline-block",
    textAlign: "right",
  },
  mainMenuBlock: {
    display: "none",
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      display: "inline-block",
    },
  },
  menuUL: {
    padding: 0,
  },
  menuItems: {
    color: "#707070",
    fontSize: "0.9rem",
    position: "relative",
    display: "inline-block",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    textDecoration: "none",
    "&:focus": {
      outline: "none",
      textDecoration: "underline",
    },
    "&:hover": {
      textDecoration: "underline",
    },
    "&:focus:before": {
      content: '""',
      top: -35,
      width: "100%",
      height: "100%",
      position: "absolute",
      borderTop: "4px solid #b5b5b5",
    },
    "&:hover:before": {
      content: '""',
      top: -35,
      width: "100%",
      height: "100%",
      position: "absolute",
      borderTop: "4px solid #b5b5b5",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 10,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 8,
    },
  },
  mobMenuItems: {
    color: "#333333",
    fontSize: "1rem",
    position: "relative",
    fontWeight: 500,
    textDecoration: "none",
    display: "block",
    padding: theme.spacing(2),
    "&:focus": {
      outline: "1px dotted",
    },
  },
  mobSelectedMenu: {
    fontWeight: "bold",
  },
  subMenuItems: {
    color: "#333333",
    textDecoration: "none",
    fontSize: "0.8rem",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:focus": {
      outline: "1px dotted",
      [theme.breakpoints.down("sm")]: {
        padding: 5,
      },
    },
    "&:hover": {
      textDecoration: "underline",
      color: "#cc0000",
    },
  },


  "@keyframes showArrow": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  

}));

const renderMenus = (props) => {
  const { menuItems, classes } = props;
  return (
    <>
      {
        <ul className={classes.menuUL}>
        <Link
        href={menuItems[0].slug}
        target={"_blank"}
        aria-label="Sign in (Opens in new tab)"
      >
          <Button className={classes.button} disableRipple variant="outlined">
            {menuItems[0].title}
          </Button>
          </Link>
          <Link
        href={menuItems[1].slug}
        target={"_blank"}
      >
          <Button
            className={classes.buttonActive}
            disableRipple
            variant="contained"
            color="secondary"
            aria-label="Apply now (Opens in new tab)"
          >
            {menuItems[1].title}
          </Button>
          </Link>
        </ul>
      }
    </>
  );
};

export const SkipNavLink = ({
  id,
  classes,
  children = "Skip to main content",
  ...props
}) => {
  return (
    <a {...props} href={`#${id}`} className={classes.skipNav}>
      {children}
    </a>
  );
};

export default function HeaderSection(props) {
  const { menu } = props;
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [mobMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeGroupMenu, setActiveGroupMenu] = useState(null);
  const [currentMenu, setCurrentMenu] = useState(null);
  const gotoHome = () => {
    history.push("/");
  };

  const desktopMenu = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const mainMenuClick = (event, menu) => {
    event.preventDefault();
    if (menu.children) {
      if (selectedMenu && menu.slug === selectedMenu.slug) {
        subMenuClose();
      } else {
        setSelectedMenu(menu);
        setSubMenuOpen(true);
      }
    } else {
      setMobileMenuOpen(false);
      history.push(menu.slug);
    }
  };

  const subMenuClose = () => {
    setSubMenuOpen(false);
    setSelectedMenu(null);
  };

  const menuProps = {
    menuItems: menu ? menu.items : [],
    classes,
    history,
    selectedMenu,
    subMenuOpen,
    setSubMenuOpen,
    mainMenuClick,
    subMenuClose,
    mobMenuOpen,
    setMobileMenuOpen,
    desktopMenu,
    activeGroupMenu,
    setActiveGroupMenu,
    setSelectedMenu,
    currentMenu,
  };

  useEffect(() => {
    if (mobMenuOpen) {
      document.body.classList.add("mob-menu-open-body");
    } else {
      document.body.classList.remove("mob-menu-open-body");
    }
  }, [mobMenuOpen]);

  const havingChildPages = (menu) => {
    let childs = menu
      ? menu.children.filter((cMenu) => cMenu.displayInMainNav === true)
      : 0;
    return (
      menu && menu.children && menu.children.length > 0 && childs.length > 0
    );
  };

  useEffect(() => {
    if (!activeGroupMenu && !havingChildPages(activeGroupMenu)) {
      subMenuClose();
      setMobileMenuOpen(false);
      setCurrentMenu(location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <SkipNavLink id="main-content" classes={classes}>
        Skip to main content
      </SkipNavLink>
      <SkipNavLink id="footer" classes={classes}>
        Skip to footer
      </SkipNavLink>
      <AppBar position="fixed" className={classes.header}>
        <Toolbar className={classes.toolBar}>
          <Typography className={classes.title} component="h1">
            <RLink to="/" className={classes.logoLink}>
              <img
                onClick={gotoHome}
                className={classes.partnerLogo}
                src={partnerLogoImg}
                alt={"Partner Logo"}
              />
            </RLink>
          </Typography>
          <nav className={classes.nav}>
            {menu && menu.items && <>{renderMenus(menuProps)}</>}
          </nav>
        </Toolbar>
      </AppBar>
    </>
  );
}
