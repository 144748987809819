import React from 'react';
import { makeStyles, Typography, Link, } from '@material-ui/core';
import { motion } from 'framer-motion';
import PartnersBackground from '../../../images/partners-banner-background.png';
import CreatorsBackground from '../../../images/creators-banner-background.png';

const SmoothLeftToRight = {
  initial: {
    x: -20,
    y: 0,
    opacity: 0,
  },
  animate: {
    x: 0,
    y: 0,
    opacity: 1,
  },
  transition: { duration: 0.7 },
};

const useStyles = makeStyles((theme) => ({
  heroBaseStyle: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  heroBanner: {
    height: 600,
    "@media (max-width: 1440px)": {
      height: 450,
    },
    [theme.breakpoints.down("sm")]: {
      height: 300,
    },
    '&$partnerBanner': {
      backgroundColor: '#CC0000',
      backgroundImage: `url(${PartnersBackground})`,
      backgroundPosition: 'right bottom', 
      backgroundSize: 'contain', 
      backgroundRepeat: 'no-repeat',
      [theme.breakpoints.down('sm')]: {
        gridRow: '2/3',
      },
    },
    '&$creatorBanner': {
      backgroundColor: '#EEEEEE',
      backgroundImage: `url(${CreatorsBackground})`,
      backgroundPosition: 'bottom', 
      backgroundSize: 'contain', 
      backgroundRepeat: 'no-repeat',
     }
  },
  partnerBanner: {
    backgroundColor: '#CC0000',
  },
  creatorBanner: {
    backgroundColor: '#EEEEEE',
  },
  heroTextWrapper: {
  },
  heroTextArea: {
    margin: '100px 46% 30px 10%',
    [theme.breakpoints.down('sm')]: {
      margin: '100px 30% 30px 10%',
    },
  },
  heroHeadingText: {
    fontSize: "3rem",
    lineHeight: "3rem",
    "@media (max-width: 1440px)": {
      fontSize: "1.8rem",
      lineHeight: "1.5rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.8rem",
      lineHeight: "2rem",
    },
  },
  heroSubText: {
    margin: "20px 0",
    fontSize: "1rem",
    lineHeight: "1.2rem",
    "@media (max-width: 1440px)": {
      fontSize: "0.7rem",
      lineHeight: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.6875rem",
      lineHeight: "1rem",
    },
  },
  whiteText: {
    color: '#FFFFFF'
  },
  redText: {
    color: '#CC0000'
  },
  greyText: {
    color: '#6D6D6D'
  },
  exploreNowButton: {
    margin: '10px 0',
    width: 'fit-content',
    textTransform: "none",
    borderRadius: 20,
    borderColor: "#CC0000",
    lineHeight: 1.75,
    border: 'none',
    backgroundColor: "#CC0000",
    color: "#FFFFFF",
    outline: 'none',
    cursor: 'pointer',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginRight: 30,
    [theme.breakpoints.down("sm")]: {
      "&:first-child": {
        margin: '10px 0',
      },
    },
  },
}));

const HeroWithImage = (props) => {
  const { values } = props;
  const classes = useStyles();

  return (
    <div className={classes.heroBaseStyle}>
      {values.banners.map( (bannerData, index) => (
        <div className={`${classes.heroTextWrapper} ${index===0 ? classes.partnerBanner : classes.creatorBanner}`}>
          <div className={classes.heroTextArea}>
            <motion.div {...SmoothLeftToRight}>
              <Typography className={`${classes.heroHeadingText} ${index===0 ? classes.whiteText : classes.greyText}`} variant={"h2"}>
                {bannerData.primaryHeading} <br />{" "}
                <span className={`${classes.heroHeadingText} ${index===0 ? classes.whiteText : classes.redText}`}>
                  {bannerData.secondaryHeading}
                </span>
              </Typography>
              <Typography color={"primary"} className={`${classes.heroSubText} ${index===0 ? classes.whiteText : classes.greyText}`}>
                {bannerData.subTextPrimary} <br />{" "}
                <span className={`${classes.heroSubText} ${index===0 ? classes.whiteText : classes.greyText}`}>
                  {bannerData.subTextSecondary}
                </span>
              </Typography>
              {(index===1) && 
                <Link
                href="/creators"
                aria-label="Join Now (Opens in new tab)"
              ><button className={classes.exploreNowButton}>Explore Now</button></Link>
              }
            </motion.div>
          </div>
        </div>
      ))}
      <div className={`${classes.heroBanner} ${classes.partnerBanner}`}></div>
      <div className={`${classes.heroBanner} ${classes.creatorBanner}`}></div>
    </div>
  );
};

export default HeroWithImage;
